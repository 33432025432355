<template>
  <!-- 个人中心--左侧公共信息 -->
  <div class="resume">
    <div class="userInfoBox">
      <img :src="circleUrl" alt="" />
      <div class="userInfoEdit">
        <p>你好,{{ userInfo.u_phone }}</p>
        <span
          class="span-btn btn-withe"
          v-prevent-repeat-click
          @click="editUserInfoBtn"
        >
          修改资料
        </span>
        <span class="span-btn btn-withe" @click="goEditPass">
          修改密码
        </span>
      </div>
    </div>
    <!-- 求职意向 -->
    <div class="resumeInfo">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span style="font-size:17px;font-weight:bold;">求职意向</span>
          <i class="el-icon-edit editPencil" @click="editWorkBtn"></i>
          <!-- <el-button
            style="float: right; padding: 3px 0"
            el-icon-edit
          ></el-button> -->
        </div>
        <div class="text item">
          期望城市：<span
            >{{
              talentResume != undefined &&
              talentResume.week_province != undefined
                ? talentResume.week_province
                : ""
            }}-{{
              talentResume != undefined && talentResume.week_city != undefined
                ? talentResume.week_city
                : ""
            }}</span
          >
        </div>
        <div class="text item">
          期望薪资：<span
            >{{
              talentInfo.week_salary != undefined ? talentInfo.week_salary : ""
            }}
            {{
              talentResume != undefined &&
              talentResume.negotiable == 1 &&
              talentResume.negotiable != undefined
                ? "面议"
                : ""
            }}
          </span>
        </div>
        <div class="text item">
          目前状态：
          <span>{{
            talentResume != undefined && talentResume.status != undefined
              ? talentResume.status
              : ""
          }}</span>
        </div>
        <div style="height: 1px;border-top: 1px solid #E8E8E8;"></div>

        <div
          class="el-card__header"
          style="padding-left: 0;padding-right:0;margin-bottom: 20px"
        >
          <div class="clearfix">
            <span style="font-size:17px;font-weight:bold;line-height: 1.7;"
              >持有证书</span
            >
            <el-button style="float: right;" size="mini" @click="editWorkBtn">
              添加证书
            </el-button>
          </div>
        </div>
        <div class="text item" v-for="(item, index) in talentCer" :key="index">
          {{ item.certificate }}-{{ item.major
          }}<span v-show="item.level">-{{ item.level }}</span
          >-{{ item.register }}
        </div>
      </el-card>
    </div>
    <!-- 今日数据 -->
    <div class="todayDate">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span style="font-size:17px;font-weight:bold;">今日数据</span>
        </div>
        <el-row :gutter="16">
          <el-col :span="8">
            <p class="todayNum">{{ todayInfo.company_see }}</p>
            <p class="text-center">企业查看</p>
          </el-col>
          <el-col :span="8">
            <p class="todayNum">{{ todayInfo.zj_see }}</p>
            <p class="text-center">猎头查看</p>
          </el-col>
          <el-col :span="8">
            <p class="todayNum">{{ todayInfo.td_job }}</p>
            <p class="text-center">投递职位</p>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <!-- 修改资料 -->
    <el-dialog
      title="编辑基本信息"
      :visible.sync="editDialog"
      :close-on-click-modal="false"
      width="560px"
    >
      <el-form
        :model="editUserInfo"
        label-width="100px"
        style="padding: 0 30px;"
      >
        <!-- :rules="editUserInfoRules" -->
        <el-form-item label="真实姓名" prop="u_name">
          <el-input v-model="editUserInfo.u_name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="u_sex">
          <el-radio-group v-model="editUserInfo.u_sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="社保地区" prop="u_social">
          <el-cascader
            size="large"
            :options="userInfoSocial"
            v-model="selectedOptions"
            :placeholder="editUserInfo.u_social"
            @change="setUseInfoSocial"
            style="width: 100%;"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="出生年月" prop="u_birth">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="editUserInfo.u_birth"
            style="width: 100%;"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="参加工作时间" prop="u_frist_work">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="editUserInfo.u_frist_work"
            style="width: 100%;"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="职称" prop="u_title">
          <el-select
            v-model="editUserInfo.u_title"
            placeholder="请选择职称"
            style="width: 100%;"
          >
            <el-option label="无" value="无"></el-option>
            <el-option label="初级" value="初级"></el-option>
            <el-option label="中级" value="中级"></el-option>
            <el-option label="高级" value="高级"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱" prop="u_eamail">
          <el-input v-model="editUserInfo.u_eamail"></el-input>
        </el-form-item>
        <el-form-item label="个人标签" prop="u_eamail">
          <el-tag
            :key="tag"
            v-for="tag in dynamicTags"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
          >
            添加
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button
          type="primary"
          v-prevent-repeat-click
          @click="editUserInfoPost"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 编辑求职意向 -->
    <el-dialog
      title="编辑求职意向"
      :visible.sync="workDialog"
      :close-on-click-modal="false"
      width="660px"
    >
      <el-form
        :model="editWorkInfo"
        ref="editWorkInfo"
        label-width="80px"
        style="padding: 0 80px 0 40px;"
      >
        <el-form-item
          v-for="(cerInfo, index) in editWorkInfo.cerList"
          label="持有证书"
          :key="cerInfo.key"
          :prop="'cerList.' + index + '.cer'"
          style="position:relative"
        >
          <el-cascader
            style="width: 70%"
            v-model="cerInfo.cer"
            :options="selCerList"
            :props="{
              checkStrictly: true,
              value: 'certificate',
              label: 'certificate',
              children: 'child',
            }"
            filterable
            clearable
          >
          </el-cascader>
          <el-select
            v-model="cerInfo.status"
            placeholder="注册情况"
            style="width: 30%"
          >
            <el-option label="初始注册" value="初始注册"></el-option>
            <el-option label="转注册" value="转注册"></el-option>
            <el-option label="初转不限" value="初转不限"></el-option>
          </el-select>
          <i class="el-icon-circle-plus-outline add-fi" @click="addCerbtn"></i>
          <i
            class="el-icon-remove-outline remove-fi"
            v-if="index > 0"
            @click="removeCerbtn(cerInfo)"
          ></i>
        </el-form-item>
        <el-checkbox
          v-model="workHidden"
          style="position: relative;top: -10px;left: 80px;"
        >
          开启隐藏
        </el-checkbox>
        <el-form-item label="期望职位">
          <el-cascader
            style="width: 100%"
            v-model="jobList"
            :options="selJobList"
            :props="{
              checkStrictly: true,
              value: 'category_name',
              label: 'category_name',
              children: 'child',
            }"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="期望城市">
          <el-cascader
            size="large"
            :options="userInfoSocial"
            v-model="expectCity"
            :placeholder="selectedCity"
            @change="setWorkInfoCity"
            style="width: 100%;"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="期望薪资">
          <el-input
            v-model="editWorkInfo.week_salary"
            style="width:45%"
            placeholder="如:2-3或3"
          ></el-input>
          <span style="padding: 0 10px">单位:(万元)</span>
          <el-checkbox v-model="workFace">面议</el-checkbox>
        </el-form-item>
        <el-form-item label="目前状态">
          <el-select
            v-model="editWorkInfo.status"
            placeholder="请选择目前状态"
            style="width: 100%"
          >
            <el-option label="合同快到期" value="1"></el-option>
            <el-option label="已考过未领取" value="2"></el-option>
            <el-option label="已退休" value="3"></el-option>
            <el-option label="应聘中" value="4"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="workDialog = false">取 消</el-button>
        <el-button
          type="primary"
          v-prevent-repeat-click
          @click="editWorkInfoPost"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      editUserInfoRules: {
        u_name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        u_sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        u_social: [
          { required: true, message: "请选择社保地", trigger: "change" },
        ],
        u_birth: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      },
      circleUrl: require("@/assets/icon/avatar.png"),
      editDialog: false, // 基础信息
      editUserInfo: {},
      selectedOptions: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      userInfoSocial: provinceAndCityData,
      workDialog: false, // 求职信息
      selCerList: [],
      todayInfo: {},
      existing: false,
      editWorkInfo: {
        cerList: [
          {
            cer: [],
            status: "",
          },
        ],
        week_city: "",
        week_salary: "",
        status: "",
        negotiable: "",
        hidden: "",
      },
      expectCity: [],
      selectedCity: "",
      workFace: false,
      workHidden: false,
      jobList: [],
      selJobList: [],
    };
  },
  computed: {
    ...mapState([
      "userInfo",
      "talentInfo",
      "talentCer",
      "talentResume",
      "talentNoLoginInfo",
    ]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  watch: {
    workDialog() {
      if (this.workDialog == false) {
        this.editWorkInfo.cerList.splice(
          1,
          this.editWorkInfo.cerList.length - 1
        );
      }
    },
  },
  methods: {
    ...mapMutations([
      "changetalentInfo",
      "changetalentCer",
      "changetalentResume",
    ]),
    gettalentInfo() {
      this.$http.post("talent/index/baseinfo").then((res) => {
        if (res.data.resultCode === 1) {
          if (res.data.resultData.base.length > 0) {
            this.changetalentInfo({
              talentInfo: res.data.resultData.base[0],
            });
          }
          this.changetalentCer({
            talentCer: res.data.resultData.cer,
          });
          if (res.data.resultData.resume.length > 0) {
            this.changetalentResume({
              talentResume: res.data.resultData.resume[0],
            });
          }
        }
      });
    },
    editUserInfoBtn() {
      if (Object.keys(this.talentInfo).length != 0) {
        this.editUserInfo = this.talentInfo;

        this.$set(this.editUserInfo, "u_social", this.talentInfo.u_social);
        if (this.talentInfo.u_ability !== "") {
          this.dynamicTags = this.talentInfo.u_ability.split(",");
        }
      }
      if (this.userInfo.u_name != "") {
        this.$set(this.editUserInfo, "u_name", this.userInfo.u_name);
        this.$set(this.editUserInfo, "u_sex", this.userInfo.u_sex.toString());
      } else if (Object.keys(this.talentNoLoginInfo).length != 0) {
        this.$set(
          this.editUserInfo,
          "u_name",
          this.talentNoLoginInfo.real_name
        );
        this.$set(
          this.editUserInfo,
          "u_sex",
          this.talentNoLoginInfo.sex.toString()
        );
      }

      this.editDialog = true;
    },
    editUserInfoPost() {
      this.editUserInfo.u_education = "";
      this.$http.post("talent/index/edit", this.editUserInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.editDialog = false;
          this.$message.success("编辑成功！");
        }
      });
    },
    // 用户信息编辑社保地区
    setUseInfoSocial() {
      let city =
        CodeToText[this.selectedOptions[0]] +
        CodeToText[this.selectedOptions[1]];
      console.log(city);
      this.editUserInfo.u_social = city;
    },
    // 添加标签
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      if (this.dynamicTags.length === 5) {
        this.$message.warning("最多只能添加五个标签！");
        return;
      }
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
        this.editUserInfo.u_ability = this.dynamicTags.toString();
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    /**
     * 求职意向
     */
    editWorkBtn() {
      this.editWorkInfo.cerList[0].cer = [];
      if (this.talentCer.length > 0) {
        this.editWorkInfo.cerList[0].cer.push(this.talentCer[0].certificate);
        this.editWorkInfo.cerList[0].cer.push(this.talentCer[0].major);
        if (this.talentCer[0].level != "") {
          this.editWorkInfo.cerList[0].cer.push(this.talentCer[0].level);
        }
        this.editWorkInfo.cerList[0].status = this.talentCer[0].register;
        for (var i = 1; i < this.talentCer.length; i++) {
          this.editWorkInfo.cerList.push({
            key: Date.now() + i,
          });
          this.editWorkInfo.cerList[i].cer = [];
          this.editWorkInfo.cerList[i].cer.push(this.talentCer[i].certificate);
          this.editWorkInfo.cerList[i].cer.push(this.talentCer[i].major);
          if (this.talentCer[i].level != "") {
            this.editWorkInfo.cerList[i].cer.push(this.talentCer[i].level);
          }
          this.editWorkInfo.cerList[i].status = this.talentCer[i].register;
        }
      }
      this.selectedCity = "";
      if (Object.keys(this.talentResume).length > 0) {
        this.selectedCity =
          this.talentResume.week_province + "-" + this.talentResume.week_city;
        this.editWorkInfo.week_province = this.talentResume.week_province;
        this.editWorkInfo.week_city = this.talentResume.week_city;
        this.editWorkInfo.week_salary = this.talentResume.week_salary;
        this.editWorkInfo.status = this.talentResume.status.toString();
        this.jobList.push(this.talentResume.position_certificate);
        this.jobList.push(this.talentResume.position_major);
        if (this.talentResume.hidden == 1) {
          this.workHidden = true;
        } else {
          this.workHidden = false;
        }
        if (this.talentResume.negotiable == 1) {
          this.workFace = true;
        } else {
          this.workFace = true;
        }
      }
      this.workDialog = true;
    },
    // 提交求职意向
    editWorkInfoPost() {
      if (this.workHidden) {
        this.editWorkInfo.hidden = 1;
      } else {
        this.editWorkInfo.hidden = 0;
      }
      if (this.workFace) {
        this.editWorkInfo.negotiable = 1;
      } else {
        this.editWorkInfo.negotiable = 0;
      }

      let arr = [];
      let emptyArr = false;
      this.editWorkInfo.cerList.forEach((element) => {
        if (element.cer == undefined || element.status == undefined) {
          this.$message.warning("完善新增证书注册情况！");
          emptyArr = true;
          return;
        }
        let cer = {};
        cer.certificate = element.cer[0];
        cer.major = element.cer[1];
        if (element.cer.length == 3) {
          cer.level = element.cer[2];
        } else {
          cer.level = "";
        }
        if (element.status != undefined) {
          cer.register = element.status;
        } else {
          this.$message.warning("未选择证书注册情况！");
          emptyArr = true;
        }
        arr.push(cer);
      });
      if (emptyArr) {
        return;
      }
      // 删除证书时
      let ids = [];
      console.log(arr);
      for (var i = 0; i < this.talentCer.length; i++) {
        if (arr[i] != undefined) {
          arr[i].id = this.talentCer[i].id;
        } else {
          ids.push(this.talentCer[i].id);
        }
      }
      if (ids.length > 0) {
        this.editWorkInfo.ids = ids;
      }
      // 已有证书新增时
      let addarr = [];
      for (var j = 0; j < arr.length; j++) {
        let arr1 = {};
        console.log(arr[j].id);
        if (arr[j].id == undefined) {
          arr1 = arr[j];
          addarr.push(arr1);
          arr.splice(j, 1);
        }
      }

      if (addarr.length > 0) {
        this.editWorkInfo.add_certificate = addarr;
      }
      this.editWorkInfo.edit_certificate = arr;
      this.editWorkInfo.position_certificate = this.jobList[0];
      this.editWorkInfo.position_major = this.jobList[1];
      this.editWorkInfo.position_leve = "初级";
      console.log(this.editWorkInfo);
      console.log(this.talentCer);
      this.$http
        .post("talent/index/objective", this.editWorkInfo)
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.workDialog = false;
            this.gettalentInfo();
          }
        });
    },
    addCerbtn() {
      this.editWorkInfo.cerList.push({
        key: Date.now(),
      });
    },
    removeCerbtn(item) {
      var index = this.editWorkInfo.cerList.indexOf(item);
      if (index !== -1) {
        this.editWorkInfo.cerList.splice(index, 1);
      }
    },
    setWorkInfoCity() {
      this.editWorkInfo.week_province = CodeToText[this.expectCity[0]];
      this.editWorkInfo.week_city = CodeToText[this.expectCity[1]];
    },
    goEditPass() {
      this.$router.push({ path: "editPassword" });
    },
    // 证书、职位下拉
    getJobList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        this.selCerList = res.data.resultData;
      });
      this.$http.post("index/certificate/postion").then((res) => {
        this.selJobList = res.data.resultData;
      });
    },
    // 今日数据
    getTodayInfo() {
      this.$http.post("talent/user/todaydata").then((res) => {
        if (res.data.resultCode === 1) {
          this.todayInfo = res.data.resultData;
        }
      });
    },
  },
  mounted() {
    this.gettalentInfo();
    this.getTodayInfo();
    this.getJobList();
  },
};
</script>
<style scoped>
.userInfoBox {
  background-repeat: no-repeat;
  height: 105px;
  background-image: url(../../assets/img/userinfoBg.png);
  background-size: contain;
}
.todayNum {
  font-size: 20px;
  font-weight: bold;
  color: #f00;
  text-align: center;
}
.userInfoBox img {
  width: 70px;
  height: 70px;
  display: block;
  float: left;
  margin: 20px;
}
.userInfoEdit {
  float: left;
  margin-top: 22px;
}
.userInfoEdit p {
  margin: 5px 0 15px;
  color: #fff;
}
.userInfoEdit span {
  margin-right: 12px;
}
.resumeInfo {
  margin-top: 24px;
}
.editPencil {
  float: right;
  padding: 3px;
  font-size: 16px;
  color: #2573f1;
  cursor: pointer;
}
.text {
  font-size: 14px;
}
.item {
  margin-bottom: 18px;
}
.todayDate {
  margin-top: 24px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.add-fi {
  position: absolute;
  font-size: 22px;
  top: 10px;
  right: -30px;
}
.remove-fi {
  position: absolute;
  font-size: 22px;
  top: 10px;
  right: -60px;
}
.add-fi:hover,
.remove-fi:hover {
  color: #2573f1;
}
</style>
